import "./headerbar.css";
import date from 'date-and-time';

export default function headerbar(){

    const now = new Date();
    date.format(now, 'YYYY/MM/DD HH:mm:ss');    // => '2015/01/02 23:14:05'
    let dateTitle = date.format(now, 'ddd, MMM DD, YYYY'); 
    dateTitle = dateTitle.toUpperCase();

    return <div className="header-bar">
    <div>Auckland, NZ</div>
    <div>{dateTitle}</div>
    <div>ONLINE VERSION</div>
  </div>
}