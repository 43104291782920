import React, { useState, useMemo } from "react";
import Player from "../player";
import "./album.css";
/*
import Eyes from './music/01. Jon Bellion - Eyes To The Sky.mp3';
import EyesImg from './images/Eyes to the sky.jpeg';
*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import YouTube from "react-youtube";

export default function Album(props) {
  /*let albums = [
    {
      title: "Eyes to the sky",
      artist: "Jon Bellion",
      img_src: EyesImg,
      src: Eyes,
    },
    {
      title: "Eyes to the sky",
      artist: "Jon Bellion",
      img_src: EyesImg,
      src: Eyes,
    },
  ];
*/
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      fs: 1,
      controls: 0,
      playsinline: 0,
      enablejsapi: 0,
      modestbranding: 1,
      disablekb: 1,
      autohide: 1,
      autoplay: 0,
      loop: 0,
      volume: 0,
      iv_load_policy: 3,
      origin: window.location.href,
      widget_referrer: window.location.href,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <div className="album">
      <h3>
        <FontAwesomeIcon icon={["fas", "compact-disc"]} /> Vibes from the past
      </h3>
      <p>description</p>

      <h4>Time machine</h4>
      <p>description</p>
      <YouTube
        videoId="IWxkcYhmY64"
        opts={opts}
        onReady={_onReady}
        loading={"lazy"}
      />

      <h4>Second chance</h4>
      <p>description</p>

      <h4>Twist of nut</h4>
      <p>description</p>

      <h4>Now is the time</h4>
      <p>description</p>
    </div>
  );
}
