import "./number.css";

export default function Number() {
  var a = new Date();
  var r = a.getDate();

  return (
    <div className="unit">
      LATE NEWS <br />
      SPECIAL <br />
      No. {r}
    </div>
  );
}
