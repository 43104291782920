import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./nav.css";
import logo from "../../assets/images/logo.svg";
import menuOpenIcon from "../../assets/images/menu-icon.png";
import menuClosedIcon from "../../assets/images/menu-icon-close.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import menuData from "../../data/menu.json";
import { HashLink } from "react-router-hash-link";
import Barnav from "../barnav";
import Number from "../../components/number";
import Headerbar from "../../components/headerbar";

export default function Nav(props, passive = false) {
  const [menu, setmenu] = useState(menuData);
  const [isMenuOpen, setisMenuOpen] = useState(false);
  
  const [currentSection, setCurrentSection] = useState(1);
  let history = useNavigate();

  useEffect(() => {
    // initiate the event handler
    //window.addEventListener("scroll", handleScroll, passive);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  //define sections' offsetTop
  const aboutTop = document.getElementById("about")
    ? document.getElementById("about").offsetTop
    : 0;
  const portfolioTop = document.getElementById("portfolio")
    ? document.getElementById("portfolio").offsetTop
    : 0;
  const contactTop = document.getElementById("contact")
    ? document.getElementById("contact").offsetTop
    : 0;

  /**
   * customize scroll callback
   */
  const handleScroll = () => {
    let idsection = 0;
    let scrollY = parseInt(window.scrollY, 10);

    if (scrollY < aboutTop) idsection = 1;

    if (scrollY >= aboutTop - 120 && scrollY < portfolioTop) idsection = 2;

    if (scrollY >= portfolioTop - 120 && scrollY < contactTop) idsection = 3;

    if (scrollY >= contactTop - 120) idsection = 4;

    //display background in sections different that home
    if (idsection === 1)
      document.getElementById("nav").classList.remove("nav-filled");
    else document.getElementById("nav").classList.add("nav-filled");

    //if page project is rendered
    let projectSection = document.getElementById("project");
    if (projectSection) {
      idsection = 3;
    }

    handlerItemMenu(null, idsection);
    activeIconTransition(idsection);
  };

  /**
   * active icon transition in each section
   * @param {*} idsection
   */
  const activeIconTransition = (idsection) => {
    if (currentSection !== idsection) {
      document.getElementById("logo").className = "nav-icon-img";
      setCurrentSection(idsection);
    }
  };

  /**
   * update the menu status
   * @param {*} e
   * @param {*} id
   * @param {*} ref
   */
  const handlerItemMenu = (e, id, ref) => {
    let oldList = menu;

    const newList = oldList.map((item) => {
      if (item.id === id) {
        let updatedItem = {
          ...item,
          isActive: true,
        };

        return updatedItem;
      } else {
        let updatedItem = {
          ...item,
          isActive: false,
        };

        return updatedItem;
      }
    });

    setmenu(newList);
    history(ref);
  };

   



  function updateIsMenuOpen() {
    console.log(isMenuOpen);
    setisMenuOpen(!isMenuOpen);
  }

  return (
    <nav>
      <div className="menu-mobile">
        <div className="menu-mobile-logo">
          <img
            id="menu-logo"
            src={logo}
            key={currentSection}
            width="25px"
            alt="Menu"
          />
        </div>
        <div className="menu-mobile-title">Passage 22</div>
        <button className="menu-mobile-icon" onClick={updateIsMenuOpen}>
          <img
            id="menu-icon"
            src={isMenuOpen ? menuClosedIcon : menuOpenIcon}
            key={currentSection}
            width="25px"
            alt="Menu"
          />
        </button>
      </div>

      <div className="menu-desktop">
        <div className="col">
          <div className="row">
            <Barnav
              isMenuOpen={isMenuOpen} 
            />
            <div className="col center banner">
              <svg
                viewBox="0 0 300 150"
                xmlns="http://www.w3.org/2000/svg"
                width="10rem"
                height="150px"
              >
                <circle className="band-logo" cx="150" cy="75" r="40" />

                <polyline
                  points="100,0 0,75 30,75 100,0"
                  className="band-logo"
                />
                <polyline points="0,75  100,150 30,75" className="band-logo" />
                <polyline
                  points="200,0 300,75 270,75 200,0"
                  className="band-logo"
                />
                <polyline
                  points="300,75  200,150   270,75"
                  className="band-logo"
                />
              </svg>
              <h1>Passage 22</h1>
            </div>
          </div>
          <Number />
        </div>

        <Headerbar />
      </div>
    </nav>
  );
}
