import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./barnav.css";

export default function Barnav(props) {
  const [page, setpage] = useState("home");
  
  
  let history = useNavigate();

  let isMenuOpen = props.isMenuOpen; 
  
  const updatePage= (page) =>  {
    setpage(page); 
    history(page);
    console.log(page)
  }



  return (
    <ul
      className={
        isMenuOpen
          ? "nav-items nav-items-active"
          : "nav-items nav-items-inactive"
      }
    >
      <li
        className={page === "home" ? "nav-item active-item" : "nav-item"}
        onClick={() => updatePage("home")}
      >
        <span>THE BAND</span>
      </li>

      <li
        className={page === "stories" ? "nav-item active-item" : "nav-item"}
        onClick={() => updatePage("stories")}
      >
        <span>STORIES</span>
      </li>

      <li
        className={page === "discography" ? "nav-item active-item" : "nav-item"}
        onClick={() => updatePage("discography")}
      >
        <span>ALBUM</span>
      </li>

      <li
        className={page === "contact" ? "nav-item active-item" : "nav-item"}
        onClick={() => updatePage("contact")}
      >
        <span>CONTACT</span>
      </li>

      <li
        className={page === "classified" ? "nav-item active-item" : "nav-item"}
        onClick={() => updatePage("classified")}
      >
        <span>CLASSIFIED</span>
      </li>
    </ul>
  );
}
