import { HashRouter as Router, Route, Navigate, Routes } from "react-router-dom";

import Nav from "./components/nav";
import Home from "./views/home";
import Discography from "./views/discography";
import Stories from "./views/stories";
import Contact from "./views/contact";
import Classified from "./views/classified";
import menu from "./data/menu.json";
import PageNotFound from "./components/Errors/404"
import "./assets/styles/reset.css";
import "./assets/styles/variables.css";

import "./App.css";

function App() {
  return (
    <Router basename="/" >
      <Nav data={menu} />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/home" element={<Home />} exact />
        <Route path="/stories" element={<Stories />} exact />
        <Route path="/discography" element={<Discography />} exact />
        <Route path="/contact" element={<Contact />} exact />
        <Route path="/classified" element={<Classified />} exact /> 
        <Route path='/404' element={<PageNotFound/>} />
        <Route path='*' element={<Navigate replace to='/404'/>} />
      </Routes>
      <footer></footer>
    </Router>
  );
}

export default App;
